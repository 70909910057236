import React, { useState } from "react";

import "./customMenu.css";

const CustomMainMenu = () => {
  // generate metaData with sample data which includes different types of jewelry.
  // https://placehold.co/120x120?text=Hello+World
  const menuData = [
    {
      mainCategory: "Rings",
      subCategory: [
        {
          name: "Engagement Rings",
          subSubCategory: [
            {
              name: "Diamond Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nDiamond Rings"
              )}`,
            },
            {
              name: "Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nGold Rings"
              )}`,
            },
            {
              name: "Silver Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nSilver Rings"
              )}`,
            },
            {
              name: "Platinum Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nPlatinum Rings"
              )}`,
            },
            {
              name: "Rose Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nRose Gold Rings"
              )}`,
            },
            {
              name: "White Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nWhite Gold Rings"
              )}`,
            },
            {
              name: "Yellow Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nYellow Gold Rings"
              )}`,
            },
            {
              name: "Titanium Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nTitanium Rings"
              )}`,
            },
            {
              name: "Tungsten Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nTungsten Rings"
              )}`,
            },
            {
              name: "Cobalt Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Engagement\nCobalt Rings"
              )}`,
            },
          ],
        },
        {
          name: "Wedding Rings",
          subSubCategory: [
            {
              name: "Diamond Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Wedding\nDiamond Rings"
              )}`,
            },
            {
              name: "Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Wedding\nGold Rings"
              )}`,
            },
            {
              name: "Silver Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Wedding\nSilver Rings"
              )}`,
            },
          ],
        },
        {
          name: "Fashion Rings",
          subSubCategory: [
            {
              name: "Diamond Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Fashion\nDiamond Rings"
              )}`,
            },
            {
              name: "Gold Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Fashion\nGold Rings"
              )}`,
            },
            {
              name: "Silver Rings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Fashion\nSilver Rings"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Earrings",
      subCategory: [
        {
          name: "Studs",
          subSubCategory: [
            {
              name: "Diamond Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Studs\nDiamond Earrings"
              )}`,
            },
            {
              name: "Gold Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Studs\nGold Earrings"
              )}`,
            },
            {
              name: "Silver Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Studs\nSilver Earrings"
              )}`,
            },
          ],
        },
        {
          name: "Hoops",
          subSubCategory: [
            {
              name: "Diamond Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Hoops\nDiamond Earrings"
              )}`,
            },
            {
              name: "Gold Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Hoops\nGold Earrings"
              )}`,
            },
            {
              name: "Silver Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Hoops\nSilver Earrings"
              )}`,
            },
          ],
        },
        {
          name: "Drops",
          subSubCategory: [
            {
              name: "Diamond Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Drops\nDiamond Earrings"
              )}`,
            },
            {
              name: "Gold Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Drops\nGold Earrings"
              )}`,
            },
            {
              name: "Silver Earrings",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Drops\nSilver Earrings"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Necklaces",
      subCategory: [
        {
          name: "Chains",
          subSubCategory: [
            {
              name: "Diamond Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chains\nDiamond Necklaces"
              )}`,
            },
            {
              name: "Gold Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chains\nGold Necklaces"
              )}`,
            },
            {
              name: "Silver Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chains\nSilver Necklaces"
              )}`,
            },
          ],
        },
        {
          name: "Pendants",
          subSubCategory: [
            {
              name: "Diamond Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Pendants\nDiamond Necklaces"
              )}`,
            },
            {
              name: "Gold Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Pendants\nGold Necklaces"
              )}`,
            },
            {
              name: "Silver Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Pendants\nSilver Necklaces"
              )}`,
            },
          ],
        },
        {
          name: "Chokers",
          subSubCategory: [
            {
              name: "Diamond Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chokers\nDiamond Necklaces"
              )}`,
            },
            {
              name: "Gold Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chokers\nGold Necklaces"
              )}`,
            },
            {
              name: "Silver Necklaces",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Chokers\nSilver Necklaces"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Bracelets",
      subCategory: [
        {
          name: "Bangles",
          subSubCategory: [
            {
              name: "Diamond Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Bangles\nDiamond Bracelets"
              )}`,
            },
            {
              name: "Gold Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Bangles\nGold Bracelets"
              )}`,
            },
            {
              name: "Silver Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Bangles\nSilver Bracelets"
              )}`,
            },
          ],
        },
        {
          name: "Cuffs",
          subSubCategory: [
            {
              name: "Diamond Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Cuffs\nDiamond Bracelets"
              )}`,
            },
            {
              name: "Gold Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Cuffs\nGold Bracelets"
              )}`,
            },
            {
              name: "Silver Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Cuffs\nSilver Bracelets"
              )}`,
            },
          ],
        },
        {
          name: "Charm Bracelets",
          subSubCategory: [
            {
              name: "Diamond Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Charm\nDiamond Bracelets"
              )}`,
            },
            {
              name: "Gold Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Charm\nGold Bracelets"
              )}`,
            },
            {
              name: "Silver Bracelets",
              image: `https://placehold.co/120x120?text=${encodeURIComponent(
                "Charm\nSilver Bracelets"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Watches",
      subCategory: [
        {
          name: "Analog",
          subSubCategory: [
            {
              name  : "Analog Watches",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Analog\nWatches"
              )}`,
            },
          ],
        },
        {
          name: "Digital",
          subSubCategory: [
            {
              name  : "Digital Watches",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Digital\nWatches"
              )}`,
            },
          ],
        },
        {
          name: "Smart",
          subSubCategory: [
            {
              name  : "Smart Watches",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Smart\nWatches"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Accessories",
      subCategory: [
        {
          name: "Sunglasses",
          subSubCategory: [
            {
              name  : "Sunglasses",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Sunglasses"
              )}`,
            },
          ],
        },
        {
          name: "Belts",
          subSubCategory: [
            {
              name  : "Belts",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Belts"
              )}`,
            },
          ],
        },
        {
          name: "Hats",
          subSubCategory: [
            {
              name  : "Hats",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Hats"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Collections",
      subCategory: [
        {
          name: "Bridal",
          subSubCategory: [
            {
              name  : "Bridal Collection",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Bridal\nCollection"
              )}`,
            },
          ],
        },
        {
          name: "Vintage",
          subSubCategory: [
            {
              name  : "Vintage Collection",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Vintage\nCollection"
              )}`,
            },
          ],
        },
        {
          name: "Luxury",
          subSubCategory: [
            {
              name  : "Luxury Collection",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Luxury\nCollection"
              )}`,
            },
          ],
        },
      ],
    },
    {
      mainCategory: "Gifts",
      subCategory: [
        {
          name: "Gifts for Her",
          subSubCategory: [
            {
              name  : "Gifts for Her",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Gifts for\nHer"
              )}`,
            },
            {
              name  : "Gifts for Him",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Gifts for\nHim"
              )}`,
            },
            {
              name  : "Gifts for Kids",
              image : `https://placehold.co/120x120?text=${encodeURIComponent(
                "Gifts for\nKids"
              )}`,
            },
          ],
        },
      ],
    }
  ];

  const [selectedMainCategoryIndex, setSelectedMainCategoryIndex] = useState(-1);
  const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] = useState(0);

  // get position in percentage
  const getPosition = (index) => {
    const align = (index < (menuData.length / 2)) ? 'left' : 'right';
    const percentage = 100 / menuData.length;
    index = (index < (menuData.length / 2)) ? index : (menuData.length - index - 1);
    const position = (percentage * index); 
    return { [align]: `${position}%` };
  }

  return (
    <div
      className="mainContainer"
      onMouseLeave={() => {setSelectedMainCategoryIndex(-1); setSelectedSubCategoryIndex(0);}}
    >
      <div className="customNavbar">
        {menuData.map((data, index) => {
          return (
            <div
              key={index}
              className={`mainCategoryButton${selectedMainCategoryIndex === index ? ' activeCategory' : ''}`}
              onMouseOver={() => setSelectedMainCategoryIndex(index)}
            >
              {data.mainCategory}
            </div>
          );
        })}
      </div>
      {
        selectedMainCategoryIndex !== -1 &&
        <div className="subCategoryContainer" style={getPosition(selectedMainCategoryIndex)}>
          {
            menuData[selectedMainCategoryIndex].subCategory.length > 1 &&
            <div className="subCategoryList mt-2">
              {
                menuData[selectedMainCategoryIndex].subCategory.map((data, subIndex) => {
                  return (
                    <div key={subIndex} className={`subCategory${selectedSubCategoryIndex === subIndex ? ' activeCategory' : ''}`} onClick={() => setSelectedSubCategoryIndex(subIndex)}>
                      {data.name}
                    </div>
                  );
                })
              }
            </div>
          }
          <div className="row mt-2 g-5">
            {
              selectedMainCategoryIndex !== -1 && selectedSubCategoryIndex !== -1 && menuData[selectedMainCategoryIndex].subCategory[selectedSubCategoryIndex].subSubCategory.map((data, subSubIndex) => {
                return (
                  <div key={subSubIndex} className='col-4 text-center subSubCategory'>
                    <img className="subSubCategoryImg" src={data.image} alt={data.name} />
                    <div className="subSubCategoryTitle">{data.name}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  );
};

export default CustomMainMenu;
